// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* BottomBar.css */
  h1, h2 {
    color: #333;
}
.bottom-bar {
    display: flex;
    justify-content: center; /* Center the buttons horizontally */
  
    padding: 10px 20px;
  }
  
  .bottom-bar button {
    margin: 0 15px; /* Adjust the margin around buttons */
    margin-top: 15px;
    padding: 8px 16px;
    height: 80px;
    width: 100px;
    border-radius: 7px;
    background-color: #e7cf80;
    color: rgb(0, 0, 0);
    font-size: 16px;
    cursor: pointer;
    border: 2px solid black;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }
  #plans-button{
    background-color: #e8c553;
    margin-top: 5px;
    height: 100px;
    width: 120px;
    font-weight: bold;
  }
  #plans-button:hover{
    background-color: #fddf7d;
  }
  
  .bottom-bar button:hover {
    background-color: #fddf7d;
  }
  `, "",{"version":3,"sources":["webpack://./src/style/BottomButtons.css"],"names":[],"mappings":"AAAA,kBAAkB;EAChB;IACE,WAAW;AACf;AACA;IACI,aAAa;IACb,uBAAuB,EAAE,oCAAoC;;IAE7D,kBAAkB;EACpB;;EAEA;IACE,cAAc,EAAE,qCAAqC;IACrD,gBAAgB;IAChB,iBAAiB;IACjB,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,yBAAyB;IACzB,mBAAmB;IACnB,eAAe;IACf,eAAe;IACf,uBAAuB;IACvB,wCAAwC;EAC1C;EACA;IACE,yBAAyB;IACzB,eAAe;IACf,aAAa;IACb,YAAY;IACZ,iBAAiB;EACnB;EACA;IACE,yBAAyB;EAC3B;;EAEA;IACE,yBAAyB;EAC3B","sourcesContent":["/* BottomBar.css */\n  h1, h2 {\n    color: #333;\n}\n.bottom-bar {\n    display: flex;\n    justify-content: center; /* Center the buttons horizontally */\n  \n    padding: 10px 20px;\n  }\n  \n  .bottom-bar button {\n    margin: 0 15px; /* Adjust the margin around buttons */\n    margin-top: 15px;\n    padding: 8px 16px;\n    height: 80px;\n    width: 100px;\n    border-radius: 7px;\n    background-color: #e7cf80;\n    color: rgb(0, 0, 0);\n    font-size: 16px;\n    cursor: pointer;\n    border: 2px solid black;\n    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);\n  }\n  #plans-button{\n    background-color: #e8c553;\n    margin-top: 5px;\n    height: 100px;\n    width: 120px;\n    font-weight: bold;\n  }\n  #plans-button:hover{\n    background-color: #fddf7d;\n  }\n  \n  .bottom-bar button:hover {\n    background-color: #fddf7d;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
