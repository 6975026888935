// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dropdown-menu {
    margin: 0 30px;
    padding: 8px 16px;
    /* width: 180px; */
    border: none;
    border-radius: 5px;
    
    color: rgb(0, 0, 0);
    font-size: 16px;
    cursor: pointer;
    border: 2px solid black;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }

  .dropdown-container{
    padding-top:30px;
  }

  .default-option {
    background-color: #fff7db;; 
  }
  
  .selected-option {
    background-color: #e7cf80;; 
  }`, "",{"version":3,"sources":["webpack://./src/style/DropdownMenu.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,iBAAiB;IACjB,kBAAkB;IAClB,YAAY;IACZ,kBAAkB;;IAElB,mBAAmB;IACnB,eAAe;IACf,eAAe;IACf,uBAAuB;IACvB,wCAAwC;EAC1C;;EAEA;IACE,gBAAgB;EAClB;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,yBAAyB;EAC3B","sourcesContent":[".dropdown-menu {\n    margin: 0 30px;\n    padding: 8px 16px;\n    /* width: 180px; */\n    border: none;\n    border-radius: 5px;\n    \n    color: rgb(0, 0, 0);\n    font-size: 16px;\n    cursor: pointer;\n    border: 2px solid black;\n    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);\n  }\n\n  .dropdown-container{\n    padding-top:30px;\n  }\n\n  .default-option {\n    background-color: #fff7db;; \n  }\n  \n  .selected-option {\n    background-color: #e7cf80;; \n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
